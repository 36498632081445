<template>
  <div class="create-content">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px">
      <div class="flex flex-wrap">
        <el-form-item label="联系人姓名" prop="contact_name">
          <el-input v-model.trim="ruleForm.contact_name" placeholder="请输入联系人姓名" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="contact_name_score" label-width="80px">
          <el-input-number v-model="ruleForm.contact_name_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="联系人手机" prop="contact_phone">
          <el-input v-model.trim="ruleForm.contact_phone" placeholder="请输入联系人手机" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="contact_phone_score" label-width="80px">
          <el-input-number v-model="ruleForm.contact_phone_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="联系人电子邮箱" prop="contact_email">
          <el-input v-model.trim="ruleForm.contact_email" placeholder="请输入联系人邮箱" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="contact_email_score" label-width="80px">
          <el-input-number v-model="ruleForm.contact_email_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="对接招商人员" prop="is_abutting_joint_JD">
          <el-radio-group v-model="ruleForm.is_abutting_joint_JD" style="width: 380px" @change="toggleJdPerson">
            <el-radio :label="0">无</el-radio>
            <el-radio :label="1">有</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分值" prop="is_abutting_joint_score" label-width="80px">
          <el-input-number v-model="ruleForm.is_abutting_joint_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap" v-if="ruleForm.is_abutting_joint_JD === 1">
        <el-form-item label="姓名" prop="name">
          <el-input v-model.trim="ruleForm.name" placeholder="请输入姓名" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="name_score" label-width="80px">
          <el-input-number v-model="ruleForm.name_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="推荐码" prop="recommend_code">
          <el-input v-model.trim="ruleForm.recommend_code" placeholder="请输入推荐码" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="recommend_code_score" label-width="80px">
          <el-input-number v-model="ruleForm.recommend_code_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="证件类型" prop="document_type">
          <el-select v-model="ruleForm.document_type" placeholder="请选择证件类型" style="width: 380px">
            <el-option label="多证合一营业执照（统一社会信用代码）" :value="0"></el-option>
            <el-option label="多证合一营业执照（非统一社会信用代码）" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="document_type_score" label-width="80px">
          <el-input-number v-model="ruleForm.document_type_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="营业执照电子版" prop="businessLicense_src">
          <el-upload class="logo-uploader"
                     :action="uploadCover"
                     :headers="headersParams"
                     name="img"
                     :on-change="(file, fileList) => handleChange(file, fileList, 1)"
                     accept=".jpg, .gif, .png, .jpeg"
                     :show-file-list="false"
                     :on-success="(res, file, fileList) => handleCoverSuccess(res, file, fileList, 1)"
                     :before-upload="(file) => beforeCoverUpload(file, 1)">
            <img v-if="ruleForm.businessLicense_src" :src="ruleForm.businessLicense_src" class="logo-cover">
            <i v-else class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
          <div class="message">图片尺寸请确保800px*800px以上，文件大小在5MB以内，支持jpg、gif、png格式，最多可上传1张</div>
        </el-form-item>
        <el-form-item label="分值" prop="businessLicense_score" label-width="80px">
          <el-input-number v-model="ruleForm.businessLicense_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="公司名称" prop="corporate_name">
          <el-input v-model.trim="ruleForm.corporate_name" placeholder="如有（），请在输入法为中文状态下输入" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="corporate_name_score" label-width="80px">
          <el-input-number v-model="ruleForm.corporate_name_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="统一社会信用代码" prop="unified_social_credit_code">
          <el-input v-model.trim="ruleForm.unified_social_credit_code" placeholder="15位的数字" clearable style="width: 380px" :maxlength="15"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="unified_social_credit_code_score" label-width="80px">
          <el-input-number v-model="ruleForm.unified_social_credit_code_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="营业执照所在地" prop="business_license_location">
          <el-cascader :props="{ label: 'value', value: 'code' }"
              placeholder="请选择营业执照所在地" style="width: 380px" v-model="ruleForm.business_license_location" :options="addressOptions"></el-cascader>
        </el-form-item>
        <el-form-item label="分值" prop="business_license_location_score" label-width="80px">
          <el-input-number v-model="ruleForm.business_license_location_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="营业执照详细地址" prop="business_license_address">
          <el-input v-model.trim="ruleForm.business_license_address" placeholder="请输入详细地址" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="business_license_address_score" label-width="80px">
          <el-input-number v-model="ruleForm.business_license_address_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="成立日期" prop="incorporation_date">
          <el-date-picker v-model="ruleForm.incorporation_date" type="date" placeholder="请选择成立日期" style="width: 380px" value-format="timestamp"></el-date-picker>
        </el-form-item>
        <el-form-item label="分值" prop="incorporation_date_score" label-width="80px">
          <el-input-number v-model="ruleForm.incorporation_date_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item prop="business_date_begin">
          <template #label>
            {{!ruleForm.business_date_type ? '营业日期' : '营业期限'}}
          </template>
          <div class="flex" style="width: 380px">
          <el-date-picker v-model="ruleForm.business_date_begin" type="date" :placeholder="!ruleForm.business_date_type ? '开始日期' : '日期期限'"
                          :style="{width: !ruleForm.business_date_type ? '150px' : '312px'}" value-format="timestamp"></el-date-picker>
          <template v-if="!ruleForm.business_date_type">
            <span style="width: 12px; text-align: center">-</span>
            <el-date-picker v-model="ruleForm.business_date_end" type="date" placeholder="结束日期" style="width: 150px" value-format="timestamp"></el-date-picker>
          </template>
          <el-checkbox v-model="ruleForm.business_date_type" style="margin-left: 15px">长期</el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="分值" prop="business_date_score" label-width="80px">
          <el-input-number v-model="ruleForm.business_date_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>

      <div class="flex flex-wrap">
        <el-form-item label="注册资本（万元）" prop="registered_capital">
          <el-input-number v-model="ruleForm.registered_capital" class="number-left" :precision="1" :min="0.1"  :max="99999999999" label="请输入注册资本" clearable style="width: 380px" :controls="false"></el-input-number>
        </el-form-item>
        <el-form-item label="分值" prop="registered_capital_score" label-width="80px">
          <el-input-number v-model="ruleForm.registered_capital_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="经营范围" prop="business_scope">
          <el-input v-model.trim="ruleForm.business_scope" placeholder="请输入经营范围" clearable style="width: 380px" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="business_scope_score" label-width="80px">
          <el-input-number v-model="ruleForm.business_scope_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="法定代表人证件类型" prop="legal_representative_certificate_type">
          <el-select v-model="ruleForm.legal_representative_certificate_type" placeholder="请选择证件类型" style="width: 380px">
            <el-option label="大陆身份证" :value="0"></el-option>
            <el-option label="护照" :value="1"></el-option>
            <el-option label="港澳居民通行证" :value="2"></el-option>
            <el-option label="台湾居民通行证" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="legal_representative_certificate_type_score" label-width="80px">
          <el-input-number v-model="ruleForm.legal_representative_certificate_type_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="法人证件电子版" prop="id_card_front_src">
          <el-upload class="logo-uploader logo-uploader-mul"
                     :action="uploadCover"
                     :headers="headersParams"
                     name="img"
                     :limit="2"
                     :on-exceed="handleExceed"
                     :on-remove="handleRemove"
                     list-type="picture-card"
                     :on-change="(file, fileList) => handleChange(file, fileList, 2)"
                     accept=".jpg, .gif, .png, .jpeg"
                     :file-list="fileListShenfen"
                     :on-success="(res, file, fileList) => handleCoverSuccess(res, file, fileList, 2)"
                     :before-upload="(file) => beforeCoverUpload(file, 2)">
            <i class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
          <div class="message">图片尺寸请确保800px*800px以上，文件大小在1MB以内，支持jpg、gif、png格式，最多可上传2张</div>
        </el-form-item>
        <el-form-item label="分值" prop="legal_person__document_electron_score" label-width="80px">
          <el-input-number v-model="ruleForm.legal_person__document_electron_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="法定代表人姓名" prop="legal_representative_name">
          <el-input v-model.trim="ruleForm.legal_representative_name" placeholder="请输入法定代表人姓名" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="legal_representative_name_score" label-width="80px">
          <el-input-number v-model="ruleForm.legal_representative_name_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="法定代表人证件号" prop="legal_representative_certificates_num">
          <el-input v-model.trim="ruleForm.legal_representative_certificates_num" placeholder="请输入法定代表人证件号" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="legal_representative_certificates_num_score" label-width="80px">
          <el-input-number v-model="ruleForm.legal_representative_certificates_num_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item prop="alidity_period_begin">
          <template #label>
            {{!ruleForm.alidity_period_type ? '有效期' : '营业期限'}}
          </template>
          <div class="flex" style="width: 380px">
            <el-date-picker v-model="ruleForm.alidity_period_begin" type="date" :placeholder="!ruleForm.alidity_period_type ? '开始日期' : '日期期限'"
                            :style="{width: !ruleForm.alidity_period_type ? '150px' : '312px'}" value-format="timestamp"></el-date-picker>
            <template v-if="!ruleForm.alidity_period_type">
              <span style="width: 12px; text-align: center">-</span>
              <el-date-picker v-model="ruleForm.alidity_period_end" type="date" placeholder="结束日期" style="width: 150px" value-format="timestamp"></el-date-picker>
            </template>
            <el-checkbox v-model="ruleForm.alidity_period_type" style="margin-left: 15px">长期</el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="分值" prop="alidity_period_score" label-width="80px">
          <el-input-number v-model="ruleForm.alidity_period_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="纳税人类型" prop="taxpayers_type">
          <el-select v-model="ruleForm.taxpayers_type" placeholder="请选择纳税人类型" style="width: 380px">
            <el-option label="一般纳税人" :value="0"></el-option>
            <el-option label="小规模纳税人" :value="1"></el-option>
            <el-option label="非增值税纳税人" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="taxpayers_type_score" label-width="80px">
          <el-input-number v-model="ruleForm.taxpayers_type_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="纳税人识别号" prop="taxpayers_identity_num">
          <el-input v-model.trim="ruleForm.taxpayers_identity_num" placeholder="15位数字" clearable style="width: 380px" :maxlength="15"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="taxpayers_identity_num_score" label-width="80px">
          <el-input-number v-model="ruleForm.taxpayers_identity_num_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="纳税类型税码" prop="yax_type">
          <el-select v-model="ruleForm.yax_type" placeholder="请选择纳税类型税码" style="width: 380px">
            <el-option v-for="(item, index) in payTaxesList" :label="item.label" :value="item.value" :key="`payTaxesList_${item.value}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="yax_type_score" label-width="80px">
          <el-input-number v-model="ruleForm.yax_type_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="税务登记电子版" prop="tax_registration_src">
          <el-upload class="logo-uploader"
                     :action="uploadCover"
                     :headers="headersParams"
                     name="img"
                     :on-change="(file, fileList) => handleChange(file, fileList, 3)"
                     accept=".jpg, .gif, .png, .jpeg"
                     :show-file-list="false"
                     :on-success="(res, file, fileList) => handleCoverSuccess(res, file, fileList, 3)"
                     :before-upload="(file) => beforeCoverUpload(file, 3)">
            <img v-if="ruleForm.tax_registration_src" :src="ruleForm.tax_registration_src" class="logo-cover">
            <i v-else class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
          <div class="message">图片尺寸请确保800px*800px以上，文件大小在5MB以内，支持jpg、gif、png格式，最多可上传1张</div>
        </el-form-item>
        <el-form-item label="分值" prop="tax_registration_score" label-width="80px">
          <el-input-number v-model="ruleForm.tax_registration_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="一般纳税人资格证电子版" prop="taxpayer_qualification_certificate_src">
          <el-upload class="logo-uploader"
                     :action="uploadCover"
                     :headers="headersParams"
                     name="img"
                     :on-change="(file, fileList) => handleChange(file, fileList, 4)"
                     accept=".jpg, .gif, .png, .jpeg"
                     :show-file-list="false"
                     :on-success="(res, file, fileList) => handleCoverSuccess(res, file, fileList, 4)"
                     :before-upload="(file) => beforeCoverUpload(file, 4)">
            <img v-if="ruleForm.taxpayer_qualification_certificate_src" :src="ruleForm.taxpayer_qualification_certificate_src" class="logo-cover">
            <i v-else class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
          <div class="message">图片尺寸请确保800px*800px以上，文件大小在1MB以内，支持jpg、gif、png格式，最多可上传1张</div>
        </el-form-item>
        <el-form-item label="分值" prop="taxpayer_qualification_certificate_score" label-width="80px">
          <el-input-number v-model="ruleForm.taxpayer_qualification_certificate_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="对公结算银行账号" prop="corporate_settlement_bank_account">
          <el-input v-model.trim="ruleForm.corporate_settlement_bank_account" placeholder="请输入对公结算银行账号" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="corporate_settlement_bank_account_score" label-width="80px">
          <el-input-number v-model="ruleForm.corporate_settlement_bank_account_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="支行联行号" prop="branch_bank_account">
          <el-input v-model.trim="ruleForm.branch_bank_account" placeholder="请输入支行联行号" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="branch_bank_account_score" label-width="80px">
          <el-input-number v-model="ruleForm.branch_bank_account_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="支行名称" prop="branch_bank_name">
          <el-input v-model.trim="ruleForm.branch_bank_name" placeholder="请输入支行名称" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="branch_bank_name_score" label-width="80px">
          <el-input-number v-model="ruleForm.branch_bank_name_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="开户银行支行所在地" prop="open_account_bank">
          <el-cascader :props="{ label: 'value', value: 'code' }"
                       placeholder="请选择开户银行支行所在地" style="width: 380px" v-model="ruleForm.open_account_bank" :options="addressOptions"></el-cascader>
        </el-form-item>
        <el-form-item label="分值" prop="open_account_bank_score" label-width="80px">
          <el-input-number v-model="ruleForm.open_account_bank_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="公司类型" prop="company_type">
          <el-select v-model="ruleForm.company_type" placeholder="请选择公司类型" style="width: 380px">
            <el-option v-for="(item, index) in companyList" :label="item.label" :value="item.value" :key="`companyList_${item.value}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="company_type_score" label-width="80px">
          <el-input-number v-model="ruleForm.company_type_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="公司网站" prop="company_web">
          <el-input v-model.trim="ruleForm.company_web" placeholder="请输入公司网站" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="company_web_score" label-width="80px">
          <el-input-number v-model="ruleForm.company_web_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="年销售额（万元）" prop="annual_sales">
          <el-input-number v-model="ruleForm.annual_sales" class="number-left" :precision="0" :min="1"  :max="999999999" label="仅输入不得超过9位数的正整数" clearable style="width: 380px" :controls="false"></el-input-number>
        </el-form-item>
        <el-form-item label="分值" prop="annual_sales_score" label-width="80px">
          <el-input-number v-model="ruleForm.annual_sales_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="商品数量" prop="goods_num">
          <el-select v-model="ruleForm.goods_num" placeholder="请选择" style="width: 380px">
            <el-option v-for="(item, index) in goodNumList" :label="item.label" :value="item.value" :key="`goodNumList_${item.value}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="goods_num_score" label-width="80px">
          <el-input-number v-model="ruleForm.goods_num_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="平均客单价" prop="avg_price">
          <el-select v-model="ruleForm.avg_price" placeholder="请选择" style="width: 380px">
            <el-option v-for="(item, index) in goodNumList" :label="item.label" :value="item.value" :key="`goodNumList_1_${item.value}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="avg_price_score" label-width="80px">
          <el-input-number v-model="ruleForm.avg_price_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="仓库情况" prop="warehouse_situation">
          <el-select v-model="ruleForm.warehouse_situation" placeholder="请选择" style="width: 380px">
            <el-option v-for="(item, index) in warehouseList" :label="item.label" :value="item.value" :key="`warehouseList_${item.value}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="warehouse_situation_score" label-width="80px">
          <el-input-number v-model="ruleForm.warehouse_situation_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="仓库地址" prop="warehouse_address">
          <el-input v-model.trim="ruleForm.warehouse_address" placeholder="请输入仓库地址" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="warehouse_address_score" label-width="80px">
          <el-input-number v-model="ruleForm.warehouse_address_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="常用物流" prop="common_logistics">
          <el-input v-model.trim="ruleForm.common_logistics" placeholder="可填写多个，用逗号分隔，如“申通，圆通”，最大50个字" clearable style="width: 380px" :maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="common_logistics_score" label-width="80px">
          <el-input-number v-model="ruleForm.common_logistics_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="ERP类型" prop="ERP_type">
          <el-select v-model="ruleForm.ERP_type" placeholder="请选择" style="width: 380px">
            <el-option v-for="(item, index) in ERPList" :label="item.label" :value="item.value" :key="`ERPList_${item.value}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="ERP_type_score" label-width="80px">
          <el-input-number v-model="ruleForm.ERP_type_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="代运营公司" prop="agent_operating_company">
          <el-input v-model.trim="ruleForm.agent_operating_company" placeholder="请输入代运营公司" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="agent_operating_company_score" label-width="80px">
          <el-input-number v-model="ruleForm.agent_operating_company_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="店铺类型" prop="store_type">
          <el-radio-group v-model="ruleForm.store_type" style="width: 380px" @change="toggleStoreType">
            <el-radio :label="0">旗舰店</el-radio>
            <el-radio :label="1">专营店</el-radio>
            <el-radio :label="2">专卖店</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分值" prop="store_type_score" label-width="80px">
          <el-input-number v-model="ruleForm.store_type_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="主营类目" prop="good_category_id">
          <el-cascader :props="{ label: 'name', value: 'id' }"
                       placeholder="请选择主营类目" style="width: 380px" v-model="ruleForm.good_category_id" :options="categoryList"></el-cascader>
        </el-form-item>
        <el-form-item label="分值" prop="good_category_score" label-width="80px">
          <el-input-number v-model="ruleForm.good_category_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="公司简称" prop="company_abbreviation">
          <el-input v-model.trim="ruleForm.company_abbreviation" placeholder="请输入公司简称" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="company_abbreviation_score" label-width="80px">
          <el-input-number v-model="ruleForm.company_abbreviation_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="经营类目描述" prop="category_description">
          <el-input v-model.trim="ruleForm.category_description" placeholder="请输入经营类目描述" clearable style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="分值" prop="category_description_score" label-width="80px">
          <el-input-number v-model="ruleForm.category_description_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
      <div class="flex flex-wrap">
        <el-form-item label="店铺后缀" prop="shop_suffix">
          <el-select v-model="ruleForm.shop_suffix" placeholder="请选择" style="width: 380px">
            <template v-for="(item, index) in shopSuffixList">
              <el-option v-if="ruleForm.store_type === item.type" :label="item.label" :value="item.value" :key="`shopSuffixList_${item.value}`"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="分值" prop="shop_suffix_score" label-width="80px">
          <el-input-number v-model="ruleForm.shop_suffix_score" class="number-left" :precision="1" :min="0.5"  :max="100" label="请输入分值" clearable style="width: 120px" :controls="false"></el-input-number>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import {addressList} from "../../../../utils/address";
import {
  validatePhone,
  validateEmail,
  validateCreditCode,
  validateTaxpayerNumber,
  validateBankNumber,
  validateBankConnectionNumber, validateUrl
} from './validator'

export default {
  props: {
    ruleForm: {
      type: Object,
      default: () => {}
    },
    fileListShenfen: {
      type: Array,
      default: () => []
    },
  },
  data() {
    let validateShenfen = (rule, value, callback) => {
      if (this.fileListShenfen.length === 0) {
        callback(new Error('请上传身份证'));
      } else if (this.fileListShenfen.length === 1) {
        callback(new Error('请上传身份证反面'));
      } else if (this.fileListShenfen.length === 2) {
        callback()
      } else {
        callback(new Error('最多上传两张'));
      }
    };
    let validateDateOperate = (rule, value, callback) => {
      if (!this.ruleForm.business_date_type) {
        if (!this.ruleForm.business_date_begin || !this.ruleForm.business_date_end) {
          return callback(new Error('请选择时间'));
        }
        return callback();
      } else {
        if (!this.ruleForm.business_date_begin) {
          callback(new Error('请选择营业期限'));
        }
        return callback();
      }
    };
    let validateValidityPeriod = (rule, value, callback) => {
      if (!this.ruleForm.alidity_period_type) {
        if (!this.ruleForm.alidity_period_begin || !this.ruleForm.alidity_period_end) {
          return callback(new Error('请选择时间'));
        }
        return callback();
      } else {
        if (!this.ruleForm.alidity_period_begin) {
          return callback(new Error('请选择营业期限'));
        }
        return callback();
      }
    };
    let validateIDNumber = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入证件号'));
      } else {
        const reg = /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/
        const reg1 = /(^[EeKkGgDdSsPpHh]\d{8}$)|(^(([Ee][a-fA-F])|([DdSsPp][Ee])|([Kk][Jj])|([Mm][Aa])|(1[45]))\d{7}$)/
        const reg2 = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/
        const reg3 = /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/
        if (this.ruleForm.legal_representative_certificate_type === 0) {
          if (reg.test(value)) {
            return callback();
          }
          return callback('请输入合法的身份证');
        }
        if (this.ruleForm.legal_representative_certificate_type === 1) {
          if (reg1.test(value)) {
            return callback();
          }
          return callback('请输入合法的护照');
        }
        if (this.ruleForm.legal_representative_certificate_type === 2) {
          if (reg2.test(value)) {
            return callback();
          }
          return callback('请输入合法的港澳居民通行证');
        }
        if (this.ruleForm.legal_representative_certificate_type === 3) {
          if (reg3.test(value)) {
            return callback();
          }
          return callback('请输入合法的台湾居民通行证');
        }
        callback(new Error("请输入合法的证件号"));
      }
    };

    let validateRecommend = (rule, value, callback) => {
      if (this.ruleForm.recommend_code_score) {
        if (!value) {
          return callback(new Error('请输入推荐码'));
        }
      }

      if(value) {
        const reg = /^\d{1,10}$/
        if (reg.test(value)) {
          return callback();
        }
        return callback(new Error("填写限制1-10个数字"));
      }
      return callback();
    };
    let validateRecommendScore = (rule, value, callback) => {
      if (this.ruleForm.recommend_code) {
        if (!value) {
          return callback(new Error('请输入分值'));
        }
        return callback();
      }
      return callback();
    };
    let validateCompanyWeb = (rule, value, callback) => {
      if (this.ruleForm.company_web_score) {
        if (!value) {
          return callback(new Error('请输入网站'));
        }
      }
      if(value) {
        const reg = /^https?:\/\/[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]+(:\d+)?(\/.*)?$/i
        if (reg.test(value)) {
          return callback();
        }
        return callback(new Error("请输入合法的网站"));
      }
      return callback();
    };
    let validateCompanyWebScore = (rule, value, callback) => {
      if (this.ruleForm.company_web) {
        if (!value) {
          return callback(new Error('请输入分值'));
        }
        return callback();
      }
      return callback();
    };
    let validateWarehouseAddress = (rule, value, callback) => {
      if (this.ruleForm.warehouse_address_score) {
        if (!value) {
          return callback(new Error('请输入仓库地址'));
        }
        return callback();
      }
      return callback();
    };
    let validateWarehouseAddressScore = (rule, value, callback) => {
      if (this.ruleForm.warehouse_address) {
        if (!value) {
          return callback(new Error('请输入分值'));
        }
        return callback();
      }
      return callback();
    };
    let validateAgentCompany = (rule, value, callback) => {
      if (this.ruleForm.agent_operating_company_score) {
        if (!value) {
          return callback(new Error('请输入代运营公司'));
        }
        return callback();
      }
      return callback();
    };
    let validateAgentCompanyScore = (rule, value, callback) => {
      if (this.ruleForm.agent_operating_company) {
        if (!value) {
          return callback(new Error('请输入分值'));
        }
        return callback();
      }
      return callback();
    };
    let validateCategoryDescription = (rule, value, callback) => {
      if (this.ruleForm.category_description_score) {
        if (!value) {
          return callback(new Error('请输入经营类目描述'));
        }
        return callback();
      }
      return callback();
    };
    let validateCategoryDescriptionScore = (rule, value, callback) => {
      if (this.ruleForm.category_description) {
        if (!value) {
          return callback(new Error('请输入分值'));
        }
        return callback();
      }
      return callback();
    };

    return {
      uploadCover: this.$api.storeSettingsUploadImg,
      headersParams: {
        Authorization: localStorage.getItem('token')
      },
      rules: {
        contact_name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        contact_name_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        contact_phone: [
          { required: true, validator: validatePhone, trigger: ['blur', 'change'] }
        ],
        contact_phone_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        contact_email: [
          { required: true, validator: validateEmail, trigger: ['blur', 'change'] }
        ],
        contact_email_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        is_abutting_joint_JD: [
          { required: true, message: '请选择对接招商人员', trigger: 'change' },
        ],
        is_abutting_joint_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        name_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        recommend_code: [
          { validator: validateRecommend, trigger: ['blur', 'change'] }
        ],
        recommend_code_score: [
          { validator: validateRecommendScore, trigger: ['blur', 'change'] }
        ],
        document_type: [
          { required: true, message: '请选择证件类型', trigger: 'change' },
        ],
        document_type_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        businessLicense_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        corporate_name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        corporate_name_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        unified_social_credit_code: [
          { required: true, validator: validateCreditCode, trigger: ['blur', 'change'] }
        ],
        unified_social_credit_code_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        business_license_location: [
          { required: true, message: '请选择营业执照所在地', trigger: 'change' },
        ],
        business_license_location_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        business_license_location_province: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        business_license_address: [
          { required: true, message: '请输入营业执照详细地址', trigger: 'blur' },
        ],
        business_license_address_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        incorporation_date: [
          { required: true, message: '请选择成立日期', trigger: 'change' },
        ],
        incorporation_date_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        business_date_begin: [
          { required: true, validator: validateDateOperate, trigger: 'change' },
        ],
        business_date_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        registered_capital: [
          { required: true, message: '请输入注册资本', trigger: 'blur' },
        ],
        registered_capital_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        business_scope: [
          { required: true, message: '请输入经营范围', trigger: 'blur' },
        ],
        business_scope_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        legal_representative_certificate_type: [
          { required: true, message: '请选择法定代表人证件类型', trigger: 'change' },
        ],
        legal_representative_certificate_type_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        legal_person__document_electron_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        legal_representative_name: [
          { required: true, message: '请输入法定代表人姓名', trigger: 'blur' },
        ],
        legal_representative_name_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        legal_representative_certificates_num: [
          { required: true, validator: validateIDNumber, trigger: ['blur', 'change'] }
        ],
        legal_representative_certificates_num_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        alidity_period_begin: [
          { required: true, validator: validateValidityPeriod, trigger: 'change' }
        ],
        alidity_period_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        taxpayers_type: [
          { required: true, message: '请选择纳税人类型', trigger: 'change' },
        ],
        taxpayers_type_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        taxpayers_identity_num: [
          { required: true, validator: validateTaxpayerNumber, trigger: ['blur', 'change'] }
        ],
        taxpayers_identity_num_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        yax_type: [
          { required: true, message: '请选择纳税类型', trigger: 'change' },
        ],
        yax_type_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        tax_registration_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        taxpayer_qualification_certificate_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        corporate_settlement_bank_account: [
          { required: true, validator: validateBankNumber, trigger: ['blur', 'change'] }
        ],
        corporate_settlement_bank_account_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        branch_bank_account: [
          { required: true, validator: validateBankConnectionNumber, trigger: ['blur', 'change'] }
        ],
        branch_bank_account_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        branch_bank_name: [
          { required: true, message: '请输入支行名称', trigger: 'blur' },
        ],
        branch_bank_name_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        open_account_bank: [
          { required: true, message: '请选择开户银行支行所在地', trigger: 'change' },
        ],
        open_account_bank_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        company_type: [
          { required: true, message: '请选择开户银行支行所在地', trigger: 'change' },
        ],
        company_type_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        company_web: [
          { validator: validateCompanyWeb, trigger: ['blur', 'change'] }
        ],
        company_web_score: [
          { validator: validateCompanyWebScore, trigger: ['blur', 'change'] }
        ],
        annual_sales: [
          { required: true, message: '请输入年销售额', trigger: 'blur' },
        ],
        annual_sales_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        goods_num: [
          { required: true, message: '请选择商品数量', trigger: 'change' },
        ],
        goods_num_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        avg_price: [
          { required: true, message: '请选择平均客单价', trigger: 'change' },
        ],
        avg_price_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        warehouse_situation: [
          { required: true, message: '请选择仓库情况', trigger: 'change' },
        ],
        warehouse_situation_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        warehouse_address: [
          { validator: validateWarehouseAddress, trigger: ['blur', 'change'] }
        ],
        warehouse_address_score: [
          { validator: validateWarehouseAddressScore, trigger: ['blur', 'change'] }
        ],
        common_logistics: [
          { required: true, message: '请输入常用物流', trigger: 'blur' },
        ],
        common_logistics_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        ERP_type: [
          { required: true, message: '请选择ERP类型', trigger: 'change' },
        ],
        ERP_type_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        agent_operating_company: [
          { validator: validateAgentCompany, trigger: ['blur', 'change'] }
        ],
        agent_operating_company_score: [
          { validator: validateAgentCompanyScore, trigger: ['blur', 'change'] }
        ],
        store_type: [
          { required: true, message: '请选择店铺类型', trigger: 'change' },
        ],
        store_type_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        good_category_id: [
          { required: true, message: '请选择主营类目', trigger: 'change' },
        ],
        good_category_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        company_abbreviation: [
          { required: true, message: '请输入公司简称', trigger: 'blur' },
        ],
        company_abbreviation_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        category_description: [
          { validator: validateCategoryDescription, trigger: ['blur', 'change'] }
        ],
        category_description_score: [
          { validator: validateCategoryDescriptionScore, trigger: ['blur', 'change'] }
        ],
        shop_suffix: [
          { required: true, message: '请选择店铺后缀', trigger: 'change' },
        ],
        shop_suffix_score: [
          { required: true, message: '请输入分值', trigger: 'blur' },
        ],
        businessLicense_src: [
          { required: true, message: '请上传营业执照电子版', trigger: 'change' },
        ],
        id_card_front_src: [
          { required: true, validator: validateShenfen, trigger: 'change' },
        ],
        tax_registration_src: [
          { required: true, message: '请上传税务登记电子版', trigger: 'blur' },
        ],
        taxpayer_qualification_certificate_src: [
          { required: true, message: '请上传一般纳税人资格证电子版', trigger: 'blur' },
        ],
      },
      // 营业执照所在地
      addressOptions: addressList,
      payTaxesList: [
        {
          value: 0,
          label: '0%',
        },
        {
          value: 1,
          label: '1%',
        },
        {
          value: 2,
          label: '3%',
        },
        {
          value: 3,
          label: '6%',
        },
        {
          value: 4,
          label: '7%',
        },
        {
          value: 5,
          label: '9%',
        },
        {
          value: 6,
          label: '10%',
        },
        {
          value: 7,
          label: '13%',
        },
        {
          value: 8,
          label: '图书9%免税',
        },
      ],
      companyList: [
        {
          value: 0,
          label: '生产厂商',
        },
        {
          value: 1,
          label: '品牌商',
        },
        {
          value: 2,
          label: '代理商',
        },
        {
          value: 3,
          label: '经销商',
        },
      ],
      goodNumList: [
        {
          value: 0,
          label: '0-100',
        },
        {
          value: 1,
          label: '100-200',
        },
        {
          value: 2,
          label: '200-500',
        },
        {
          value: 3,
          label: '大于500',
        },
      ],
      warehouseList: [
        {
          value: 0,
          label: '自有仓库',
        },
        {
          value: 1,
          label: '第三方仓库',
        },
        {
          value: 2,
          label: '无仓库',
        },
      ],
      ERPList: [
        {
          value: 0,
          label: '自有ERP',
        },
        {
          value: 1,
          label: '第三方ERP代运营',
        },
        {
          value: 2,
          label: '无',
        },
      ],
      categoryList: [],
      shopSuffixList: [
        {
          value: 0,
          label: '专营店',
          type: 1
        },
        {
          value: 1,
          label: '专卖店',
          type: 2
        },
        {
          value: 2,
          label: '旗舰店',
          type: 0
        },
        {
          value: 3,
          label: '官方旗舰店',
          type: 0
        },
      ],
    }
  },
  created() {
    this.getCategoryList()
  },
  methods: {
    toggleJdPerson(val) {
      if (val === 0) {
        this.$set(this.ruleForm, 'name', '')
        this.$set(this.ruleForm, 'name_score', void 0)
      }
    },
    toggleStoreType(val) {
      this.ruleForm.shop_suffix = null
    },
    getCategoryList() {
      this.$http.axiosGet(this.$api.storeSettingsGetCategory, (res) => {
        if (res.code === 200) {
          this.categoryList = res.data
        } else {
          this.$message.warning(res.msg)
        }
      }, (err) => {
        console.log(err)
      })
    },
    // start 上传图片
    beforeCoverUpload(file, type) {
      const isLt5M = file.size / 1024 / 1024 <= 5;
      const isLt1M = file.size / 1024 / 1024 <= 1;
      if ((type === 1 || type === 3) && !isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      if ((type === 2 || type === 4) && !isLt1M) {
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      const isSize = new Promise((resolve, reject) => {
        const width = 800
        const height = 800
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = () => {
          const valid = img.width >= width && img.height >= height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        this.$message.warning('图片尺寸请确保800px*800px以上')
        return Promise.reject()
      })
      if ((type === 1 || type === 3)) {
        return isLt5M && isSize
      } else {
        return isLt1M && isSize
      }
    },
    handleCoverSuccess(res, file, fileList, type) {
      if (res.code === 200) {
        if (type === 1) {
          this.ruleForm.businessLicense_src = res.data; //无域名
        }
        if (type === 2) {
          // this.$emit('update:fileListShenfen', fileList)
          this.fileListShenfen.push(file)
        }
        if (type === 3) {
          this.ruleForm.tax_registration_src = res.data; //无域名
        }
        if (type === 4) {
          this.ruleForm.taxpayer_qualification_certificate_src = res.data; //无域名
        }
        this.$message.success('上传成功')
      } else {
        this.$message.error('图片上传失败，请稍后再试~')
      }
    },
    handleChange(uploadFile, uploadFiles, type) {
      if (type === 1 && this.ruleForm.businessLicense_src) {
        this.$refs.ruleForm.validateField('businessLicense_src')
      }
      if (type === 2 && this.fileListShenfen.length > 0) {
        this.$refs.ruleForm.validateField('id_card_front_src')
      }
      if (type === 3 && this.ruleForm.tax_registration_src) {
        this.$refs.ruleForm.validateField('tax_registration_src')
      }
      if (type === 4 && this.ruleForm.taxpayer_qualification_certificate_src) {
        this.$refs.ruleForm.validateField('taxpayer_qualification_certificate_src')
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 2 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleRemove(file, fileList) {
      let findIndex = this.fileListShenfen.findIndex(item => item.uid === file.uid)
      this.fileListShenfen.splice(findIndex, 1)

      this.$refs.ruleForm.validateField('id_card_front_src')
    },
    // end 上传图片
  }
}
</script>

<style scoped lang="scss">
.create-content {
  margin: 60px 0;
}
.big-title {
  width: 380px;
  font-size: 16px;
}
:deep(.logo-uploader) {
  width: 120px;
  height: 120px;
  .el-upload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F7F7F7;
  }
  .logo-cover {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
  .logo-uploader-icon {
    color: #999;
    font-size: 24px;
  }
  &.logo-uploader-mul {
    display: flex;
    width: 100%;
    .el-upload {
      &.el-upload--picture-card {
        width: 120px;
        height: 120px;
        border: none;
        background: #F7F7F7;
      }
    }
    .el-upload-list__item {
      width: 120px;
      height: 120px;
    }
  }
}
.message {
  width: 380px;
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  margin-top: 4px;
}
</style>